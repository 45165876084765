import React from 'react';
import { useIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import {
    FILE_NAME,
    SECTION_IDS,
    SECTION_TRANSLATIONS,
} from 'views/style-guide/constants';
import { Specification } from 'modules/projects/components/specification';
import { SectionsNavigationBar } from 'modules/technologies/sections-navigation-bar';

import { DownloadButton } from '../download-button';

const SContainer = styled.div`
    content-visibility: auto;
    contain-intrinsic-size: 1000px;
`;

const SSpecification = styled(Specification)`
    margin-bottom: 6.25rem;
`;

const SSectionsNavigationBar = styled(SectionsNavigationBar)`
    margin-bottom: 6.25rem;
`;

const SDownloadButton = styled(DownloadButton)`
    margin-bottom: 1rem;
`;

export const StyleGuideMainSection = () => {
    const { formatMessage } = useIntl();

    return (
        <>
            <SContainer>
                <SSpecification
                    wrapperCss={css`
                        margin-bottom: 6.25rem;
                    `}
                    texts={{
                        topSubTitle: 'style-guide.main-section.header',
                        title: 'style-guide.main-section.headline',
                        subtitle: 'style-guide.main-section.subtitle',
                    }}
                    customButtonsComponent={
                        <SDownloadButton
                            fileName={FILE_NAME.BRAND_BOOK}
                            target="_blank"
                        >
                            {formatMessage({
                                id: 'style-guide.main-section.download-brand-book',
                            })}
                        </SDownloadButton>
                    }
                    isBoxStyle
                />
            </SContainer>
            <SSectionsNavigationBar
                data={SECTION_TRANSLATIONS}
                ids={SECTION_IDS}
            />
        </>
    );
};
