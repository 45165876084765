import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import MRLogoBlack from 'svgs/logos/mr-logo-black.svg';

import { ALLOWED_LOGO_BACKGROUNDS } from '../../constants';

const SBackgroundLabel = styled.div`
    margin-bottom: 0.75rem;
    font-weight: 700;
    text-transform: uppercase;
`;

const SListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const SBackgroundItem = styled.div`
    background-color: ${({ color }) => color};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;
`;

const SMRLogoBlack = styled(MRLogoBlack)`
    width: 100%;
    max-width: 25rem;
    height: auto;
    flex-grow: 1;
`;

export const LogoBackgroundExample = () => {
    const { formatMessage } = useIntl();

    return (
        <SListContainer>
            {ALLOWED_LOGO_BACKGROUNDS.map(({ color, labelKey }) => (
                <div key={color}>
                    <SBackgroundLabel>
                        {formatMessage({ id: labelKey })}
                    </SBackgroundLabel>
                    <SBackgroundItem color={color}>
                        <SMRLogoBlack />
                    </SBackgroundItem>
                </div>
            ))}
        </SListContainer>
    );
};
