import React from 'react';
import { Button } from 'components/button-new';
import { BUTTON_VARIANTS } from 'components/button-new/variants';
import { node, oneOf, string } from 'prop-types';

export const DownloadButton = ({
    children,
    className,
    fileName,
    variant,
    target,
}) => {
    return (
        <Button
            component="a"
            to={`/resources/${fileName}`}
            variant={variant}
            target={target}
            className={className}
            download
            isFilled
        >
            {children}
        </Button>
    );
};

DownloadButton.propTypes = {
    className: string,
    children: node,
    fileName: string,
    variant: oneOf(BUTTON_VARIANTS),
    target: string,
};

DownloadButton.defaultProps = {
    variant: '',
    target: '_self',
};
