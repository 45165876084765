import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import { useTranslatedTitle } from 'hooks/use-translated-title';

import { ColorSwatch } from './components/color-swatch';
import { BRAND_COLORS } from './constants';

const SHeaderContainer = styled.div`
    text-align: center;
    margin-bottom: 5rem;
`;
const SHeaderSecond = styled(HeaderSecond)`
    text-align: center;
    margin-bottom: 1.5rem;
`;

const SRow = styled.div`
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    column-gap: 1rem;
    row-gap: 2rem;
    margin-bottom: 2rem;
    flex-wrap: wrap;

    ${CONSTANTS.MEDIA.max1280`
      grid-template-columns: repeat(8, 1fr);
    `}

    ${CONSTANTS.MEDIA.max900`
      grid-template-columns: repeat(6, 1fr);
    `}

    ${CONSTANTS.MEDIA.max768`
      grid-template-columns: repeat(4, 1fr);
    `}

    ${CONSTANTS.MEDIA.max500`
      grid-template-columns: repeat(3, 1fr);
      column-gap: 0.75rem;
      row-gap: 1.5rem;
    `}

    ${CONSTANTS.MEDIA.max370`
      grid-template-columns: repeat(2, 1fr);
    `}
`;

const SColorSwatch = styled(ColorSwatch)`
    width: 100%;
`;

const SRowTitle = styled.div`
    font-weight: 700;
    font-size: 1.375rem;
    margin-bottom: 2rem;
    margin-top: 4rem;
`;

export const ColorsSection = () => {
    const title = 'style-guide.colors-section.header';
    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(title);

    return (
        <Container id="style-guide-colors">
            <SHeaderContainer>
                <SHeaderSecond
                    topParagraph="style-guide.colors-section.headline"
                    id={kebabCaseId}
                    handleCopyUrl={handleCopyUrl}
                >
                    <FormattedMessage id={title} />
                </SHeaderSecond>
                <Paragraph>
                    <FormattedMessage id="style-guide.colors-section.description" />
                </Paragraph>
            </SHeaderContainer>

            {Object.entries(BRAND_COLORS).map(
                ([key, { rows, isShadeNameTranslated }]) => (
                    <div key={key}>
                        <SRowTitle>
                            <FormattedMessage
                                id={`style-guide.colors-section.${key}`}
                            />
                        </SRowTitle>
                        {rows.map((row, index) => (
                            <SRow key={index}>
                                {Object.entries(row).map(([name, value]) => (
                                    <SColorSwatch
                                        key={name}
                                        value={value}
                                        name={
                                            isShadeNameTranslated ? (
                                                <FormattedMessage
                                                    id={`style-guide.colors-section.${name}`}
                                                />
                                            ) : (
                                                name
                                            )
                                        }
                                    />
                                ))}
                            </SRow>
                        ))}
                    </div>
                ),
            )}
        </Container>
    );
};
