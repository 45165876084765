import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled, { css } from 'styled-components';
import { FILE_NAME } from 'views/style-guide/constants';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import { useTranslatedTitle } from 'hooks/use-translated-title';
import { ContentRows } from 'modules/content-rows';

import { DownloadButton } from '../download-button';

import { LOGOS } from './constants';
import { useLogotypeGuidelines } from './helpers';

const SHeaderContainer = styled.div`
    text-align: center;
    margin-bottom: 2rem;
`;

const SHeaderSecond = styled(HeaderSecond)`
    text-align: center;
    margin-bottom: 1.5rem;
`;

const STopContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

const SInfoBox = styled.p`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;
    border-radius: 0.5rem;
    padding: 1rem 3.5rem;
    background-color: var(--primary-color-90);
    font-weight: 700;

    ${CONSTANTS.MEDIA.max768`
        padding: 1rem 2rem;
    `}
`;

const SDownloadButton = styled(DownloadButton)`
    margin-bottom: 4rem;
`;

const SLogoItem = styled.div`
    display: flex;
    gap: 2.5rem;
    flex-direction: column;
    align-items: center;
`;

const SLogoItemLabel = styled.div`
    font-weight: 700;
    font-size: 1rem;
    text-transform: uppercase;
`;

const SLogoList = styled.div`
    display: flex;
    gap: 4.5rem;
    flex-direction: column;
    margin-bottom: 8rem;
`;

const SContentRows = styled(ContentRows)`
    padding: 0;
`;

export const LogotypeSection = () => {
    const logotypeGuidelines = useLogotypeGuidelines();
    const title = 'style-guide.logotype-section.header';
    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(title);

    return (
        <Container id="style-guide-logotype">
            <SHeaderContainer>
                <SHeaderSecond
                    topParagraph="style-guide.logotype-section.headline"
                    id={kebabCaseId}
                    handleCopyUrl={handleCopyUrl}
                >
                    <FormattedMessage id={title} />
                </SHeaderSecond>
                <Paragraph>
                    <FormattedMessage id="style-guide.logotype-section.description" />
                </Paragraph>
            </SHeaderContainer>

            <STopContainer>
                <SInfoBox>
                    <FormattedMessage id="style-guide.logotype-section.remember-to-consult" />
                </SInfoBox>
                <SDownloadButton
                    variant="outline"
                    fileName={FILE_NAME.LOGOTYPE_PACKAGE}
                >
                    <FormattedMessage id="style-guide.logotype-section.download-logotype-pack" />
                </SDownloadButton>
            </STopContainer>

            <SLogoList>
                {LOGOS.map(({ labelKey, logo }, index) => (
                    <SLogoItem key={index}>
                        <SLogoItemLabel>
                            <FormattedMessage id={labelKey} />
                        </SLogoItemLabel>

                        {logo}
                    </SLogoItem>
                ))}
            </SLogoList>

            <SContentRows
                columnsData={logotypeGuidelines}
                textColumnCss={css`
                    white-space: pre-wrap;
                `}
            />
        </Container>
    );
};
