import React from 'react';
import styled from 'styled-components';
import { node, string } from 'prop-types';

const SContainer = styled.div`
    min-height: 10rem;
    border-radius: 1.5rem;
    padding: 1rem 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
    flex-direction: column;
    border: 1px solid var(--gray-color);
    text-align: center;
`;

const STitle = styled.h4`
    font-size: 3.625rem;
    font-weight: ${({ fontWeight }) => fontWeight};
`;

export const TypographyCard = ({ subtitle, title, fontWeight, className }) => {
    return (
        <SContainer className={className}>
            {subtitle && <span>{subtitle}</span>}
            {title && <STitle fontWeight={fontWeight}>{title}</STitle>}
        </SContainer>
    );
};

TypographyCard.propTypes = {
    className: string,
    subtitle: node,
    fontWeight: string,
    title: node.isRequired,
};

TypographyCard.defaultProps = {
    fontWeight: '700',
};
