export const SECTION_IDS = [
    'style-guide-logotype',
    'style-guide-typography',
    'style-guide-colors',
];

export const SECTION_TRANSLATIONS = [
    'style-guide.logotype-section.headline',
    'style-guide.typography-section.headline',
    'style-guide.colors-section.headline',
];

export const FILE_NAME = {
    LOGOTYPE_PACKAGE: 'mobile-reality_logotype.zip',
    TYPOGRAPHY_PACKAGE: 'mobile-reality_typography.zip',
    BRAND_BOOK: 'mobile-reality_brand-book.pdf',
};
