import React from 'react';
import { StyleGuideView } from 'views/style-guide';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import messages from 'constants/translations/resources/style-guide.json';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const StyleGuidePage = (props) => {
    const { location } = props;

    return (
        <Layout location={location} messages={messages}>
            <StyleGuideView />
        </Layout>
    );
};

StyleGuidePage.propTypes = {
    location: object.isRequired,
};

export default StyleGuidePage;

export const Head = () => <SEO tags={TAGS.STYLE_GUIDE} />;
