import React from 'react';
import styled from 'styled-components';
import MRLogo from 'svgs/logos/mr-logo.svg';
import MRLogoMark from 'svgs/logos/mr-logo-mobile-2.svg';

const SMRLogo = styled(MRLogo)`
    width: min(28.75rem, 90%);
    height: auto;
`;

const SMRLogoMark = styled(MRLogoMark)`
    width: 6rem;
    height: auto;
`;

export const ALLOWED_LOGO_BACKGROUNDS = [
    {
        color: '#f53103',
        labelKey:
            'style-guide.logotype-section.backgrounds-sub-section.on-primary-background',
    },
    {
        color: '#040d23',
        labelKey:
            'style-guide.logotype-section.backgrounds-sub-section.on-secondary-background',
    },
];

export const LOGOS = [
    {
        labelKey: 'style-guide.logotype-section.full-logotype',
        logo: <SMRLogo />,
    },
    {
        labelKey: 'style-guide.logotype-section.mark',
        logo: <SMRLogoMark />,
    },
];
