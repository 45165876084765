import React from 'react';
import styled from 'styled-components';

import { ColorsSection } from './components/colors-section';
import { LogotypeSection } from './components/logotype-section';
import { StyleGuideMainSection } from './components/style-guide-main-section';
import { TypographySection } from './components/typogrpahy-section';

const Container = styled.div`
    width: 100%;
`;

export const StyleGuideView = () => {
    return (
        <Container>
            <StyleGuideMainSection />
            <LogotypeSection />
            <TypographySection />
            <ColorsSection />
        </Container>
    );
};
