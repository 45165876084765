const PRIMARY_SHADES = {
    50: '#FFEAE5',
    100: '#FFEAE5',
    200: '#FBAD9A',
    300: '#F98368',
    400: '#F75A35',
    500: '#F53103',
    600: '#C42702',
    700: '#931D02',
    800: '#621401',
    900: '#310A01',
};

const SECONDARY_SHADES = {
    100: '#D1DDFA',
    200: '#A3BAF5',
    300: '#6278AF',
    400: '#182A57',
    500: '#040D23',
    600: '#01050F',
};

const NEUTRAL_SHADES = {
    0: '#FFFFFF',
    50: '#F6F8F9',
    200: '#D9D9D9',
    300: '#CAC9D1',
    400: '#B5B5BA',
    600: '#747378',
    800: '#24232B',
    900: '#121115',
};

const ADDITIONAL_TEAL_SHADES = {
    'T-50': '#ECF8FA',
    'T-100': '#D9F2F5',
    'T-200': '#B4E4EB',
    'T-300': '#8ED7E1',
    'T-400': '#69C9D7',
    'T-500': '#43BCCD',
    'T-600': '#3696A4',
};

const ADDITIONAL_PURPLE_SHADES = {
    'P-50': '#F5E9F4',
    'P-100': '#EACCE7',
    'P-500': '#972192',
    'P-600': '#84157F',
};

const SEMANTIC_COLORS = {
    success: '#28B13E',
    error: '#F53103',
    warning: '#FF9900',
};

export const BRAND_COLORS = {
    primary: {
        rows: [PRIMARY_SHADES],
        isShadeNameTranslated: false,
    },
    secondary: {
        rows: [SECONDARY_SHADES],
        isShadeNameTranslated: false,
    },
    neutral: {
        rows: [NEUTRAL_SHADES],
        isShadeNameTranslated: false,
    },
    additional: {
        rows: [ADDITIONAL_TEAL_SHADES, ADDITIONAL_PURPLE_SHADES],
        isShadeNameTranslated: false,
    },
    semantic: {
        rows: [SEMANTIC_COLORS],
        isShadeNameTranslated: true,
    },
};
