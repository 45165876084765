import React from 'react';
import { useIntl } from 'react-intl';
import MRLogoSpacing from 'svgs/style-guide/mr-logo-spacing.svg';
import MRLogoStructure from 'svgs/style-guide/mr-logo-structure.svg';

import { LogoBackgroundExample } from '../components/logo-background-example';

export const useLogotypeGuidelines = () => {
    const { formatMessage } = useIntl();

    return [
        {
            title: 'style-guide.logotype-section.backgrounds-sub-section.headline',
            descriptions: [
                formatMessage({
                    id: 'style-guide.logotype-section.backgrounds-sub-section.description',
                }),
            ],
            sideContent: <LogoBackgroundExample />,
        },
        {
            title: 'style-guide.logotype-section.signature-sub-section.headline',
            descriptions: [
                formatMessage({
                    id: 'style-guide.logotype-section.signature-sub-section.description',
                }),
            ],
            img: <MRLogoSpacing height="100%" width="100%" />,
            alt: formatMessage({
                id: 'nft-subpage.smartContractsDevelopmentAlt',
            }),
            isReversed: true,
        },
        {
            title: 'style-guide.logotype-section.protection-area-sub-section.headline',
            descriptions: [
                formatMessage({
                    id: 'style-guide.logotype-section.protection-area-sub-section.description',
                }),
            ],
            img: <MRLogoStructure height="100%" width="100%" />,
            alt: formatMessage({
                id: 'nft-subpage.businessDrivenDevelopmentAlt',
            }),
        },
    ];
};
