import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { FILE_NAME } from 'views/style-guide/constants';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { Paragraph } from 'components/paragraph';
import { CONSTANTS } from 'constants/styles/constants';
import { useTranslatedTitle } from 'hooks/use-translated-title';

import { DownloadButton } from '../download-button';

import { TypographyCard } from './components/typography-card';
import { FONT_NAME, FONT_WEIGHTS } from './constants';

const SContainer = styled(Container)`
    background-color: var(--gray-color-90);
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;
const SHeaderContainer = styled.div`
    text-align: center;
    margin-bottom: 5rem;
`;

const SHeaderSecond = styled(HeaderSecond)`
    text-align: center;
    margin-bottom: 1.5rem;
`;

const SHeaderDescription = styled(Paragraph)`
    white-space: pre-line;
`;

const SDownloadButton = styled(DownloadButton)`
    margin-bottom: 5rem;
`;

const SPropertySection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5rem;
    align-self: stretch;
`;

const SPropertyContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    align-items: center;
`;

const SPropertyLabel = styled.h3`
    text-align: center;
    font-size: 1.375rem;
`;

const SFontFamilyCard = styled(TypographyCard)`
    padding: 0 7.8125rem;

    ${CONSTANTS.MEDIA.max700`
      padding: 0;
      width: 100%;
    `}
`;

const SFontWeightList = styled.div`
    display: flex;
    gap: 1.25rem;

    ${CONSTANTS.MEDIA.max800`
      width: 100%;
    `}

    ${CONSTANTS.MEDIA.max500`
      flex-direction: column;
  `}
`;

const SFontWeightCard = styled(TypographyCard)`
    ${CONSTANTS.MEDIA.max800`
        padding: 0;
        flex-grow: 1;
    `}
`;

export const TypographySection = () => {
    const title = 'style-guide.typography-section.header';
    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(title);

    return (
        <SContainer id="style-guide-typography">
            <SHeaderContainer>
                <SHeaderSecond
                    topParagraph="style-guide.typography-section.headline"
                    id={kebabCaseId}
                    handleCopyUrl={handleCopyUrl}
                >
                    <FormattedMessage id={title} />
                </SHeaderSecond>
                <SHeaderDescription>
                    <FormattedMessage id="style-guide.typography-section.description" />
                </SHeaderDescription>
            </SHeaderContainer>

            <SDownloadButton
                fileName={FILE_NAME.TYPOGRAPHY_PACKAGE}
                variant="outline"
            >
                <FormattedMessage id="style-guide.typography-section.download-typography-pack" />
            </SDownloadButton>

            <SPropertySection>
                <SPropertyContainer>
                    <SPropertyLabel>
                        <FormattedMessage id="style-guide.typography-section.font-family" />
                    </SPropertyLabel>
                    <SFontFamilyCard title={FONT_NAME} />
                </SPropertyContainer>
                <SPropertyContainer>
                    <SPropertyLabel>
                        <FormattedMessage id="style-guide.typography-section.font-weight" />
                    </SPropertyLabel>
                    <SFontWeightList>
                        {Object.entries(FONT_WEIGHTS).map(([value, name]) => (
                            <SFontWeightCard
                                key={value}
                                title={value}
                                fontWeight={value}
                                subtitle={
                                    <FormattedMessage
                                        id={`style-guide.typography-section.${name}`}
                                    />
                                }
                            />
                        ))}
                    </SFontWeightList>
                </SPropertyContainer>
            </SPropertySection>
        </SContainer>
    );
};
