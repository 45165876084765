import React from 'react';
import styled from 'styled-components';
import { string } from 'prop-types';

const SSwatchContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 6.75rem;
`;

const SSwatch = styled.div`
    flex: 1;
    aspect-ratio: 1 / 1;
    margin-bottom: 1rem;
    border-radius: 0.75rem;
    background-color: ${(props) => props.value};
`;

const SColorName = styled.p`
    margin-bottom: 0.375rem;
    font-weight: 700;
    line-height: 1em;
`;

const SColorValue = styled.p`
    color: var(--gray-color-80);
    font-size: 0.875rem;
    line-height: 1em;
`;

export const ColorSwatch = ({ value, name, className }) => {
    return (
        <SSwatchContainer className={className}>
            <SSwatch value={value} />
            <SColorName>{name}</SColorName>
            <SColorValue>{value}</SColorValue>
        </SSwatchContainer>
    );
};

ColorSwatch.propTypes = {
    value: string.isRequired,
    name: string.isRequired,
    className: string,
};
